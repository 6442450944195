
<template>
    <v-container>
        <data_table
            :displayname="' Inventory BHA'"
            :options="true"
            :loader="get_data_load"
            :headers="headers"
            :search_text="'Search (Order nr, PO, Item Number etc..)'"
            :items="pipes"
            :pdf_params="[]"
            :type="$store.getters.getPipeType"
        />
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import data_table from '@/components/data_table.vue';

export default {
    name: "inventoryBHA",
    components: { data_table },
    data() {
        return {
            pipes: {BHA: []},
            error: null,
            loading: false,
            headers: [
                { text: 'Serial Number', value: 'Serial_No' },
                { text: 'Delivery-ticket', value: 'DT_No' },
                { text: 'Order Number', value: 'Order_No' },
                { text: 'Connection Box', value: 'Connection_Box' },
                { text: 'Connection Pin', value: 'Connection_Pin' },
                { text: 'Last Inspection Date', value: 'Last_Inspection_Date' },
                { text: 'Current Location', value: 'Current_Location' },
                { text: 'Equipment type', value: 'Equipment_Type' },
                { text: 'Rack', value: 'Rack' },
                { text: 'Rig', value: 'Rig' },
                { text: 'Assets', value: 'Assets' },
                { text: 'Site', value: 'Site' },
            ]
        }
    },

    mounted() {
        this.getPipes()
    },
    computed: {
        ...mapGetters(["getUsername"]),
    },
    methods: {
        getPipes() {
            this.loading = true;
            axios.get("/getInventoryBHA", {params: {username: this.getUsername}}).then((response) => {
                this.pipes['BHA'] = response.data;
                this.loading = false;
            });
        },
    }
}
</script>