<template>
  <v-container>

    <v-snackbar v-model="showSnackbar" timeout="3000" color="red" :top="true">
      {{ this.snackbarText }}
      <v-btn text @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <!-- INPUT -->
    <v-row style="display: flex; justify-content: center;">
      <!-- Item Number Selection -->
      <v-col cols="3">
        <v-select v-model="selectedItem" :items="selectableItems" label="Select item number ..." dark>
        </v-select>
      </v-col>

      <!-- Date Range Selection -->
      <v-col cols="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-combobox dark v-model="dates" multiple chips small-chips label="Date range" readonly v-bind="attrs"
              v-on="on">
            </v-combobox>
          </template>
          <v-date-picker v-model="dates" range multiple no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save([])">Clear</v-btn>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
          </v-date-picker>

        </v-menu>
      </v-col>

      <!-- Order Number Selection -->
      <v-col cols="3">
        <v-select v-model="selectedOrder" multiple :items="selectableOrders"
          :label="selectedOrder.length > 0 ? selectedOrder.join() : selectableOrders.length > 0 ? 'All orders related to item and period' : 'No orders found for selected item'"
          dark>
        </v-select>
      </v-col>
      <v-col cols="1" align-self="center">
        <v-btn @click="fetchStats" :disabled="selectedItem === ''" dark>GO</v-btn>
      </v-col>
    </v-row>

    <v-container v-if="!loading">
      <DrillPipeStatistics v-if="this.equipment === 'DP'" :drillPipeData="this.componentData"
        @rowClicked="dpRowClicked">
      </DrillPipeStatistics>
      <DrillCollarStatistics v-if="equipment === 'DC'" :drillCollarData="this.componentData" @rowClicked="dpRowClicked">
      </DrillCollarStatistics>
      <HeavyWeightStatistics v-if="equipment === 'HW'" :heavyWeightData="this.componentData" @rowClicked="dpRowClicked">
      </HeavyWeightStatistics>
      <OCTGStatistics v-if="this.equipment === 'OCTG' || this.equipment === 'TBG'" :OCTGData="this.componentData"
        @rowClicked="dpRowClicked">
      </OCTGStatistics>
    </v-container>
    <v-progress-linear v-else indeterminate dark :height="20">Loading data...</v-progress-linear>
  </v-container>
</template>

<script>
import axios from 'axios';
import Histogram from './components/IPCCard.vue'
import PipeStatusPieChartCard from './components/PipeStatusPieChartCard.vue';
import TUTJ from './components/TUTJ.vue';
import DrillPipeStatistics from './components/DrillPipeStatisticsView.vue';
import DrillCollarStatistics from './components/DrillCollarStatisticsView.vue';
import HeavyWeightStatistics from './components/HeavyWeightStatisticsView.vue';
import OCTGStatistics from './components/OCTGStatisticsView.vue';

export default {
  components: {
    Histogram,
    PipeStatusPieChartCard,
    TUTJ,
    DrillPipeStatistics,
    DrillCollarStatistics,
    HeavyWeightStatistics,
    OCTGStatistics,
  },
  computed: {
    selectableItems() {
      if (this.selectedItem === '') return Object.keys(this.itemToOrders);
      const selectedEquipment = this.extractEquipmentFromItemno(this.selectedItem);
      return Object.keys(this.itemToOrders);
    },
    selectableOrders() {
      var ordersFilteredByItem = this.selectedItem === '' ? [] : this.itemToOrders[this.selectedItem]['Orders'];
      if (this.dates.length > 0) {
        ordersFilteredByItem = ordersFilteredByItem.filter(o => {
          const date = new Date(o["date"]);
          return date >= new Date(this.dates[0]) && date <= new Date(this.dates[1]);
        });
      }
      return ordersFilteredByItem.map(o => o["ono"]).sort().reverse();
    },
  },
  data() {
    return {
      snackbarText: 'Please select an item number',
      showSnackbar: false,
      equipment: '',
      loading: true,
      hasIPC: false,
      itemToOrders: {},
      selectedItem: '',
      selectedOrder: [],
      menu: false,
      dates: [],
      componentData: {},
      multiHorisontalChartColorMapping: {
        Total: '#2b1d0e',
        Pin: '#70675d',
        Box: '#aab1b7'

      }
    }
  },
  watch: {
    selectedOrder(newVal, oldVal) {
      console.log('selectedOrder changed from', oldVal, 'to', newVal);
      // Add your logic here to react to changes in selectedOrder
    },
    selectedItem(newVal, oldVal) {
      console.log('selectedItem changed from', oldVal, 'to', newVal);
      this.selectedOrder = [];
      // Add your logic here to react to changes in selectedItem
    },
  },
  mounted() {
    axios
      .get('/itemsAndOrders', {
        params: {
          username: this.$store.getters.getUsername
        }
      })
      .then(response => {
        const tempDict = {}
        console.log(response.data)
        Object.keys(response.data).forEach((key) => {
          if (["Drill Pipe", "Drill Collar", "Tubing", "OCTG", "Heavy Weight"].includes(response.data[key].Equipment)) {
            tempDict[key] = response.data[key]
          }
        });
        this.itemToOrders = tempDict;
        this.loading = false;
      });
  },
  methods: {
    extractEquipmentFromItemno(itemno) {
      const regex = /\d+([A-Z]+)\.\d+/;
      const match = itemno.match(regex);
      console.log(match)
      return match ? match[1] : null;
    },
    dpRowClicked(row) {
      this.selectedOrder = [];
      this.selectedOrder.push(row.Order_No)
      console.log("Selected order", this.selectedOrder)
      this.fetchStats();
    },
    fetchStats() {
      console.log("Fetching stats", this.selectedItem, this.selectedOrder.length)
      // If no item is selected, return a snack bar
      this.loading = true;
      if (this.selectedItem === '') {
        this.snackbarText = 'Please select an item number';
        this.showSnackbar = true;
        this.loading = false;
        return;
      } else {
        // user has selected item, check if dates are selected
        if (this.dates.length === 2 && this.selectableOrders.length === 0) {
          this.snackbarText = 'No orders for item(s): ' + this.selectedItem + ' for period ' + this.dates.join(' - ');
          this.showSnackbar = true;
          this.loading = false;
          return;
        }
      }

      console.log("ORDERS", this.selectedOrder.length > 0 ? this.selectedOrder.join() : this.selectableOrders.join())
      axios
        .get('/statistics/item', {
          params: {
            orders: this.selectedOrder.length > 0 ? this.selectedOrder.join() : this.selectableOrders.join(),
            itemNumber: this.selectedItem
          }
        })
        .then(response => {
          console.log(response.data.items)
          this.componentData.IPC = response.data.items.IPC;
          this.componentData.Machining_Data = Object.entries(response.data.items.Machining_Data).map(([key, val]) => {
            return { data: val, name: key, color: this.multiHorisontalChartColorMapping[key] }
          })
          this.componentData.Hardbanding = Object.entries(response.data.items.Hardbanding ? response.data.items.Hardbanding : []).map(([key, val]) => {
            return { data: val, name: key, color: this.multiHorisontalChartColorMapping[key] }
          })
          this.componentData.pipeStatus = response.data.items.Pipe_Status ? response.data.items.Pipe_Status : {};
          this.componentData.TJ_Tooljoint = response.data.items.TJ_Tooljoint;
          this.componentData.TU_Tube = response.data.items.TU_Tube;
          this.componentData.itemData = response.data.items.ItemData;
          this.componentData.tongspace = response.data.items.Tongspace;
          this.componentData.od = response.data.items.OD;
          this.componentData.wall = response.data.items.Wall
          this.componentData.listOfOrderData = response.data.items.Orders ? response.data.items.Orders : [];
          // for each order in listOfOrderData, format the Inspection_Date 
          this.componentData.listOfOrderData.forEach((order) => {
            order.Date_Formatted = this.$formatDate(order.Inspection_Date);
          });
          this.componentData.minimum_values = response.data.items.minimum_values
          this.componentData.quantity_inspected = response.data.items.quantity_inspected ? response.data.items.quantity_inspected : 0;
          this.equipment = this.extractEquipmentFromItemno(this.selectedItem);
          var stuff = {
            pin: {
              recut: (100 * response.data.items.Machining_Data.Pin.SUM / (response.data.items.Machining_Data.Pin.SUM + response.data.items.Machining_Data.Pin.OK + response.data.items.Machining_Data.Pin.SRF + response.data.items.Machining_Data.Pin.FR + response.data.items.Machining_Data.Pin.RF)).toFixed(1),
              srf: (100 * response.data.items.Machining_Data.Pin.SRF / (response.data.items.Machining_Data.Pin.SUM + response.data.items.Machining_Data.Pin.OK + response.data.items.Machining_Data.Pin.SRF + response.data.items.Machining_Data.Pin.FR + response.data.items.Machining_Data.Pin.RF)).toFixed(1),
              ok: (100 * (response.data.items.Machining_Data.Pin.OK + response.data.items.Machining_Data.Pin.RF + response.data.items.Machining_Data.Pin.FR)  / (response.data.items.Machining_Data.Pin.SUM + response.data.items.Machining_Data.Pin.OK + response.data.items.Machining_Data.Pin.SRF + response.data.items.Machining_Data.Pin.FR + response.data.items.Machining_Data.Pin.RF)).toFixed(1),
              rej: (100 * response.data.items.Machining_Data.Pin.REJ / (response.data.items.Machining_Data.Pin.SUM + response.data.items.Machining_Data.Pin.OK + response.data.items.Machining_Data.Pin.SRF + response.data.items.Machining_Data.Pin.FR + response.data.items.Machining_Data.Pin.RF)).toFixed(1)
            },
            box: {
              recut: (100 * response.data.items.Machining_Data.Box.SUM / (response.data.items.Machining_Data.Box.SUM + response.data.items.Machining_Data.Box.OK + response.data.items.Machining_Data.Box.SRF + response.data.items.Machining_Data.Box.FR + response.data.items.Machining_Data.Box.RF)).toFixed(1),
              srf: (100 * response.data.items.Machining_Data.Box.SRF / (response.data.items.Machining_Data.Box.SUM + response.data.items.Machining_Data.Box.OK + response.data.items.Machining_Data.Box.SRF + response.data.items.Machining_Data.Box.FR + response.data.items.Machining_Data.Box.RF)).toFixed(1),
              ok: (100 * (response.data.items.Machining_Data.Box.OK + response.data.items.Machining_Data.Box.RF + response.data.items.Machining_Data.Box.FR) / (response.data.items.Machining_Data.Box.SUM + response.data.items.Machining_Data.Box.OK + response.data.items.Machining_Data.Box.SRF + response.data.items.Machining_Data.Box.FR + response.data.items.Machining_Data.Box.RF)).toFixed(1),
              rej: (100 * response.data.items.Machining_Data.Box.REJ / (response.data.items.Machining_Data.Box.SUM + response.data.items.Machining_Data.Box.OK + response.data.items.Machining_Data.Box.SRF + response.data.items.Machining_Data.Box.FR + response.data.items.Machining_Data.Box.RF)).toFixed(1)
            }
          };
          this.componentData.machining_rates = [
            { connection: "Pin", ok: stuff.pin.ok, srf: stuff.pin.srf, rej: stuff.pin.rej, recut: stuff.pin.recut },
            { connection: "Box", ok: stuff.box.ok, srf: stuff.box.srf, rej: stuff.box.rej, recut: stuff.box.recut }

          ]
          if (response.data.items.Hardbanding) {
            console.log("IS HARBANDING")
            var hb_rates = {
              box: {
                rej: (100 * response.data.items.Hardbanding.Box.REJ / (response.data.items.Hardbanding.Box.SUM_HB + response.data.items.Hardbanding.Box.OK)).toFixed(1),
                ok: (100 * response.data.items.Hardbanding.Box.OK / (response.data.items.Hardbanding.Box.SUM_HB + response.data.items.Hardbanding.Box.OK)).toFixed(1),
                recut: (100 * response.data.items.Hardbanding.Box.SUM_HB / (response.data.items.Hardbanding.Box.SUM_HB + response.data.items.Hardbanding.Box.OK)).toFixed(1)
              }
            }
  
            this.componentData.hb_rates = [
              { connection: "Box", ok: hb_rates.box.ok, rej: hb_rates.box.rej, recut: hb_rates.box.recut }
            ]
          }

          console.log("Component data", this.componentData);
          this.loading = false
        });
    },
  }
}
</script>
<style scoped>
::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th {
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child {
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child {
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
</style>