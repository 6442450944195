<script>
import { CONSTANTS } from '../constants.js';
import ItemCard from '../components/ItemCard.vue'
import Histogram from '../components/IPCCard.vue'
import PipeStatusPieChartCard from '../components/PipeStatusPieChartCard.vue';
import HorizontalBarCard from '../components/HorizontalBarCard.vue'
import { barData, horisontalMultiBarData, formatDataPie } from '../dataformatter';
import OrderBreakdown from './OrderBreakdown.vue';
import RecutRateTable from './RecutRateTable.vue';

export default {
  name: 'DrillCollarStatistics',
  props: {
    drillCollarData: {
      type: Object,
    },
  },
  components: {
    RecutRateTable,
    ItemCard,
    Histogram,
    PipeStatusPieChartCard,
    HorizontalBarCard,
    OrderBreakdown,
  },
  computed: {
  },
  data() {
    return {
      orderDataTableHeaders: [
      {
          text: 'Item nr',
          value: 'Item_No',
          align: 'start',
        },
        {
          text: 'Order nr',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        {
          text: 'Inspection date',
          sortable: true,
          value: 'Inspection_Date'
        },
        { text: "Quantity", sortable: true, value: "Quantity" },
        { text: "Connection pin recut sum", sortable: true, value: "Machining_Pin_Sum_Recut" },
        { text: "Pin SRF", value: "Machining_Pin_SRF" },
        { text: "Connection box recut sum", sortable: true, value: "Machining_Box_Sum_Recut" },
        { text: "Box SRF", value: "Machining_Box_SRF" },
      ],
      machining_card_title: CONSTANTS.MACHINING_CARD_TITLE,
      machining_rates_header: [
        { text: "Connection", value: "connection", align: "start" },
        { text: "Ok", value: "ok", align: "start" },
        { text: "Srf", value: "srf", align: "start" },
        { text: "Reject", value: "rej", align: "start" },
        { text: "Recut", value: "recut", align: "start" },
      ],
    }
  },
  methods: {
    barData,
    horisontalMultiBarData,
    formatDataPie,
    handleRowClick(row) {
      this.$emit("rowClicked", row)
    }
  }
}
</script>


<template>
  <v-container>
    <!-- 1st row -->
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E" elevation="0">
          <ItemCard :itemObject="this.drillCollarData.itemData" />
        </v-card>
      </v-col>
    </v-row>

    <!-- 2nd row -->
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'TS Pin' + ' (Min criteria: ' +
            this.drillCollarData.minimum_values.TS_Pin + ')' }}</v-card-title>
          <Histogram
            :IPCData="barData('Tongspace Pin', this.drillCollarData.tongspace.Pin.x, this.drillCollarData.tongspace.Pin.y)"
            :title="'Tongspace Pin'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'TS Box' + ' (Min criteria: ' +
            this.drillCollarData.minimum_values.TS_Box + ')' }}</v-card-title>
          <Histogram
            :IPCData="barData('Tongspace Box', this.drillCollarData.tongspace.Box.x, this.drillCollarData.tongspace.Box.y)"
            :title="'Tongspace Box'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'Box OD' + ' (Min criteria: ' +
            this.drillCollarData.minimum_values.OD_Box + ')'
          }}</v-card-title>
          <Histogram
            :IPCData="barData('OD Box', this.drillCollarData.od.Box.x, this.drillCollarData.od.Box.y)"
            :title="'Box OD'" />
        </v-card>
      </v-col>
    </v-row>

    <!-- 4th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <v-card class="pa-2 cardWidth" min-height="800px" dark color="#1C355E">
          <v-card-title>{{ this.machining_card_title + " " +
            this.drillCollarData.quantity_inspected + "x inspections"
          }}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.drillCollarData.Machining_Data)"
            :height="'100%'">
          </HorizontalBarCard>
          <v-card-subtitle class="text-start">
            Recut rates
          </v-card-subtitle>
          <RecutRateTable
            :recutData="{ listOfRecutRates: this.drillCollarData.machining_rates, recutRatesTableHeaders: this.machining_rates_header }">
          </RecutRateTable>
        </v-card>
      </v-col>
    </v-row>

    <!-- 5th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <OrderBreakdown :orderData="{
          listOfOrderData: this.drillCollarData.listOfOrderData,
          orderDataTableHeaders: orderDataTableHeaders
        }" @rowClicked="handleRowClick" :equipmentType="'Drill Collar'">

        </OrderBreakdown>

      </v-col>
    </v-row>
  </v-container>
</template>