var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-10",attrs:{"dark":"","color":"transparent","width":"100%","elevation":"0"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.displayname))]),_c('v-container',{staticClass:"d-flex justify-center",attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","clearable":"","label":_vm.search_text,"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.options)?_c('v-checkbox',{staticClass:"d-inline mx-4",attrs:{"label":"Completed","value":"completed"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.options)?_c('v-checkbox',{staticClass:"d-inline mx-4",attrs:{"label":"Ongoing","value":"ongoing"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.selected_columns.length > 0)?_c('v-select',{staticClass:"temp",attrs:{"placeholder":"Filter columns","items":_vm.headers,"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v("Selected columns: "+_vm._s(_vm.selected_columns.length))]):_vm._e()]}}],null,false,4176047382),model:{value:(_vm.selected_columns),callback:function ($$v) {_vm.selected_columns=$$v},expression:"selected_columns"}}):_vm._e(),(_vm.$route.name === 'inspectionBHA')?_c('v-btn',{attrs:{"color":"transparent","elevation":"0"},on:{"click":_vm.selectAllPDF}},[_vm._v(_vm._s(_vm.pdf_button_text))]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"loading":_vm.loader,"headers":_vm.headers,"items":_vm.table_items,"search":_vm.search,"item-class":'orange',"sort-by":_vm.defaultSort,"sort-desc":true,"options":_vm.pagination,"footer-props":{'items-per-page-options':[5, 10, 50, 100, -1]}},on:{"update:page":_vm.pageChange,"current-items":_vm.getShowingItems,"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header.multiple",fn:function(ref){
var header = ref.header;
return [_c('div',{staticStyle:{"cursor":"pointer","border":"1px solid #1C355E","text-align":"center"},on:{"click":_vm.multiple_download}},[_c('span',[_vm._v("Download Selected")]),_c('v-icon',{attrs:{"color":"#1C355E"}},[_vm._v("mdi-download")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.getPDF_func(_vm.type, item, _vm.pdf_params)}}},[_c('v-icon',[_vm._v("description")])],1)]}},{key:"item.multiple",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item,"multiple":""},model:{value:(_vm.selectedPDF),callback:function ($$v) {_vm.selectedPDF=$$v},expression:"selectedPDF"}})]}},{key:"item.Inspection_Date",fn:function(ref){
var item = ref.item;
return [_c('td',{style:({backgroundColor: _vm.date_colorizer(item.Date_Formatted)})},[_c('span',[_vm._v(_vm._s(item.Date_Formatted))])])]}},{key:"item.Return_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}},{key:"item.Returned_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}},{key:"item.Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$route.name === 'inventory')?_c('span',_vm._g(_vm._b({class:_vm.getDateClass(item.Date_Formatted)},'span',attrs,false),on)):_vm._e()]}}],null,true)},[_c('div',{staticClass:"tooltip-text"},[_vm._v(" Green: Tool accepted with last inspection within last 3 months"),_c('br'),_vm._v(" Yellow: Tool accepted with last inspection between 0 to 3 months left"),_c('br'),_vm._v(" Red: Last inspection more than 6 months ago ")])])]}},{key:"item.DateExpiration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getInspectionExpiration(item.Date_Formatted)))])]}},{key:"item.Connection_1",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Connection_1))])]}},{key:"item.Connection_2",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Connection_2))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }